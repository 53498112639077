@import "../../../../styles/breakpoints";
@import "../../../../styles/utils";

.submittedPurchaseOrders {
  padding: 0 pixelToRem(16);
}

.submittedPurchaseOrders_withPOSore {
  padding-top: pixelToRem(16);
}

@include tabletUp {
  .submittedPurchaseOrders {
    padding: 0 pixelToRem(24);
  }

  .submittedPurchaseOrders_withPOSore {
    padding-top: pixelToRem(33);
  }
}
