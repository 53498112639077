@import "../../../styles/breakpoints";
@import "../../../styles/utils";

.sectionWrapper {
  padding-top: pixelToRem(32);

  [collapsed-sidebar] &.sectionWrapper {
    margin-left: pixelToRem(-20);
  }
}

@include tabletUp {
  .sectionWrapper {
    padding-top: pixelToRem(27);
  }
}
