@import "../../../../../styles/utils";
@import "../../../../../styles/breakpoints";

.formCardContent {
  padding: pixelToRem(16);
}

@include tabletUp {
  .formCardContent {
    padding: pixelToRem(16) 0;
  }
}
