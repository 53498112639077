@import "../../../../../styles/utils";
@import "../../../../../styles/colors";

.formCardTitle {
  padding: 0 pixelToRem(16);
  background-color: $rac-dark-grey;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: pixelToRem(40);
  font-size: pixelToRem(16);
  font-weight: 700;
}
