@import "../../../styles/breakpoints";
@import "../../../styles/utils";

.sectionWrapper {
  padding-top: pixelToRem(32);
}

@include tabletUp {
  .sectionWrapper {
    padding-top: pixelToRem(27);
  }
}
