@import "../../../styles/utils";

.centered {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: pixelToRem(32);
    font-weight: 700;
    margin: 2rem;
  }
}

.note {
  margin: 1rem 0 5rem 0;
  text-align: center;
  width: 200px;
}

.signin {
  color: cornflowerblue;
  padding: 0.75rem 3rem;
  outline: none;
}
