@import './colors.scss';

$border-width: 1px;

@mixin border-button($color: $rac-blue) {
  background-color: $white;
  border: 2px solid $color;
  color: $color;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 17px;
  outline: none;
}

@mixin fill-button($color: $white, $backgroundColor: $rac-medium-grey) {
  background-color: $backgroundColor;
  border: 0;
  color: $color;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 17px;
  outline: none;
}

@mixin cardTitle($fontSize, $radius: 0, $backgroundColor: $rac-dark-grey) {
  background-color: $backgroundColor;
  @if $radius != 0 {
    border-radius: $radius;
  }

  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: $white;
  display: flex;
  font-size: $fontSize;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  line-height: 30px;
  padding: 0.4375rem 1.125rem;

  .cardTitleNote {
    color: $white;
    font-family: 'Open Sans' sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 23px;
  }
}

@mixin cardFooter($fontSize, $radius: 0, $backgroundColor: $rac-dark-grey, $color: $white) {
  background-color: $backgroundColor;
  @if $radius != 0 {
    border-radius: $radius;
  }

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  color: $color;
  display: flex;
  font-size: $fontSize;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  line-height: 29px;
  padding: 0.4375rem 1.125rem;
}

$base-size: 16;
@function pixelToRem($target, $context: $base-size) {
  @return ($target / $context) * 1rem;
}
