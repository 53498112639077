/** Colors **/

/** Fonts **/
$font-family-base: 'Open Sans', sans-serif;
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.75;


/** Padding **/
$padding-xs: 0.5rem;
$padding-xxs: $padding-xs / 2;
$padding-sm: $padding-xs * 2;
$padding-md: $padding-xs * 3;
$padding-lg: $padding-xs * 4;
$padding-xl: $padding-xs * 5;
$padding-xxl: $padding-xs * 6;

/** Margins **/
$margin-xs: 0.5rem;
$margin-xxs: $margin-xs / 2;
$margin-sm: $margin-xs * 2;
$margin-md: $margin-xs * 3;
$margin-lg: $margin-xs * 4;
$margin-xl: $margin-xs * 5;
$margin-xxl: $margin-xs * 6;

/** Buttons **/

/** Forms **/

/** Z-indexes **/
