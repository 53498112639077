@import "../../../styles/colors";
@import "../../../styles/utils";
@import "../../../styles/breakpoints";

.nav {
  padding: 0 pixelToRem(12);
  border-bottom: 2px solid $rac-blue;
}

.navItem {
  display: inline-block;
  text-decoration: none;
  text-align: center;
  background-color: $rac-blue;
  border: 2px solid transparent;
  border-bottom: none;
  position: relative;
  bottom: -2px;
  margin: 0 pixelToRem(4) 0 pixelToRem(4);
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: pixelToRem(12);
  letter-spacing: 0.97px;

  > button {
    font-weight: inherit;
    width: 100%;
    padding: pixelToRem(7) pixelToRem(15);
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: $white;
    border-color: $rac-blue;
    color: $rac-blue;
  }
}

/* BEGIN Inhibit bootstrap styles from container app on .navItem */
a.navItem {
  text-decoration: none;
  color: $white;

  button:focus {
    outline: none;
  }

  &.active {
    color: $rac-blue;
  }
}
/* END */

@include mobileSmallUp {
  .navItem {
    font-size: pixelToRem(14);
    min-width: pixelToRem(158);
  }
}

@include mobileMediumUp {
  .navItem {
    min-width: pixelToRem(170);
  }
}

@include tabletUp {
  .nav {
    padding: 0 pixelToRem(20);
  }
}
