@import "../../../../../styles/colors";
@import "../../../../../styles/breakpoints";
@import "../../../../../styles/utils";

.cardContainer {
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin: 0 0 1rem 0;

  h2,
  h6,
  p {
    margin: 0;
  }

  h6 {
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.cardTitlePO {
  @include cardTitle(
    $radius: 8px,
    $fontSize: 0.875rem,
    $backgroundColor: $rac-blue
  );
  font-weight: 600;
  padding: 0 1rem 0 1.125rem;
}

.deleteContainer {
  cursor: pointer;
  fill: $white;
  height: 22px;
  width: 22px;
}

.cardContentPO {
  padding: 0.5rem 0.875rem 0.5rem;
}

.cardFooterPO {
  border-radius: 0 0 pixelToRem(8) pixelToRem(8);
  font-size: pixelToRem(11);
  line-height: pixelToRem(20);
  background-color: $rac-light-grey;
  color: $rac-dark-grey;
  padding: pixelToRem(5) pixelToRem(16);
}

.textFooterBold {
  font-weight: 600;
}

.cardSeparator {
  border: 0;
  border-top: 2px solid $rac-light-grey;
  height: 0;
  margin: 0.25rem 0 0 0;
  opacity: 0.6;
}

.type {
  color: $rac-dark-grey;
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.25px;
  line-height: 23px;
  text-align: left;
  white-space: nowrap;
}

.value {
  color: $rac-dark-grey;
  font-size: 0.875rem;
  padding: 0 0.25rem;
  font-weight: 700;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100% - 20px);

  &.brand {
    color: $black;
    font-weight: 600;
    height: 20px;
    padding: 0;
    text-transform: capitalize;
  }

  &.description {
    font-size: 1rem;
    font-weight: 700;
    padding: 0;
  }

  input {
    background-color: transparent;
    border: none;
    font-weight: 700;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 65px;
    color: $rac-dark-grey;
  }
}

.noValue {
  color: $rac-dark-grey;
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.25px;
  line-height: 19px;
  padding: 0 0.25rem;

  &.brand {
    font-style: italic;
    font-weight: 600;
  }

  &.description {
    font-size: 1rem;
    font-style: italic;
  }
}

.divideMobile {
  display: flex;
  align-items: center;

  .value {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h6 {
    &:first-of-type {
      flex: 0 0 60%;
      max-width: 60%;
    }
    &:last-of-type {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

.cardFooterPOStatus {
  display: inline-block;
}

@include tabletUp {
  .cardContainer {
    h2 {
      &.noValue {
        margin-bottom: pixelToRem(5);
      }
    }
  }
  .cardTitlePO {
    font-weight: 700;
    line-height: pixelToRem(22);
    padding: pixelToRem(8) pixelToRem(16) pixelToRem(9);

    > span {
      padding: pixelToRem(4) 0;
    }
  }

  .cardContentPO {
    padding: pixelToRem(14) pixelToRem(16) pixelToRem(18) pixelToRem(31);
  }

  .divideMobile {
    display: block;
    align-items: unset;
    h6 {
      &:last-of-type,
      &:last-of-type {
        flex: 0 0 auto;
        max-width: none;
      }
    }
  }

  .value,
  .noValue {
    color: $black;
  }

  .value {
    &.description {
      font-size: pixelToRem(18);
      line-height: pixelToRem(22);
    }
    input {
      text-overflow: unset;
      overflow: visible;
      max-width: none;
      color: $black;
      -webkit-text-fill-color: $black;
    }
  }

  .cardContentPOHeader {
    margin-bottom: pixelToRem(10);
  }

  .cardContentPOInner {
    padding-top: pixelToRem(5);
  }

  .noValue {
    font-weight: 700;
    &.description {
      font-size: pixelToRem(18);
      line-height: pixelToRem(22);
    }
  }

  .cardFooterPO {
    color: $black;
    font-size: pixelToRem(14);
    padding: pixelToRem(14) pixelToRem(16);
    text-align: right;
  }
  .textFooterBold {
    font-weight: 400;
  }
  .type {
    color: $black;
  }
}
