@import '../../../../../styles/colors';
@import '../../../../../styles/utils';

.headline {
  font-weight: 700;

  &.text {
    margin-bottom: 5px;
  }
}

.text {
  text-align: center;
  color: #FFFFFF;
  font-size: pixelToRem(14);
  margin: 0
}
