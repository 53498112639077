@import "../../../../styles/colors";
@import "../../../../styles/utils";
@import "../../../../styles/breakpoints";

.messageBox {
  background-color: $rac-dark-grey;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: pixelToRem(12) pixelToRem(44);
  margin-bottom: pixelToRem(80);
}

@include tabletUp {
  .messageBox {
    width: pixelToRem(440);
    margin-bottom: pixelToRem(170);
  }
}
