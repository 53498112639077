@import "../../../../styles/colors";
@import "../../../../styles/utils";
@import "../../../../styles/breakpoints";

/* BEGIN Inhibit bootstrap styles */
.link:hover {
  color: $white;
  text-decoration: none;
}
/* END */

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $rac-green;
  font-size: pixelToRem(20);
  font-weight: 600;
  letter-spacing: pixelToRem(1.25);
  line-height: pixelToRem(24);
  text-transform: uppercase;
  color: $white;
  text-decoration: none;
  border-radius: pixelToRem(8);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 0 pixelToRem(22) 0 pixelToRem(11);
  text-align: center;
  z-index: 20;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pixelToRem(40);
    height: pixelToRem(40);
  }
}

@include tabletUp {
  .link {
    padding: pixelToRem(3) pixelToRem(11);
  }

  .text {
    font-size: pixelToRem(14);
    font-weight: 700;
    line-height: pixelToRem(15);
  }
}
