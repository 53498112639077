@import "../../../../../../../styles/utils";

.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: pixelToRem(24);

  > label {
    flex: 0 0 pixelToRem(76);
    max-width: pixelToRem(76);
    margin-right: pixelToRem(8);
  }

  > div {
    width: 100%;
    flex-grow: 1;
    margin-right: pixelToRem(8);
  }

  > button {
    flex: 0 0 pixelToRem(40);
    max-width: pixelToRem(40);
  }
}
