@import "../../../styles/colors";
@import "../../../styles/utils";

.inputControlItem {
  display: block;
  width: 100%;
  height: pixelToRem(40);
  padding: pixelToRem(8);
  font-size: pixelToRem(14);
  font-weight: 600;
  line-height: pixelToRem(19);
  color: $rac-black-medium;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid $rac-blue;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: $rac-black-medium;
  }
  &::-moz-placeholder {
    color: $rac-black-medium;
  }
  &:-ms-input-placeholder {
    color: $rac-black-medium;
  }
  &:-moz-placeholder {
    color: $rac-black-medium;
  }
}

.small {
  height: pixelToRem(32);
  padding: pixelToRem(5) pixelToRem(8);
}

.disabled {
  background-color: $rac-grey-disabled;
  border-color: $rac-dark-grey;
  user-select: none;
}

.error {
  border: 1px solid $rac-red !important;
  color: $rac-red;
}

.opacity02 {
  opacity: 0.2;
}
