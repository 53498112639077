@import "../../../styles/colors";
@import "../../../styles/utils";

/* Tooltip container */
.tooltip {
  visibility: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: pixelToRem(41);
  height: pixelToRem(41);
  display: flex;
  align-items: center;
  justify-content: center;

  .tooltiptext {
    visibility: hidden;
    width: pixelToRem(103);
    background-color: $black;
    color: $white;
    text-align: left;
    padding: pixelToRem(7);
    font-size: smaller;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    right: 0;
    top: calc(100% - 2px);

    &::after {
      content: " ";
      position: absolute;
      bottom: 100%;
      /* At the top of the tooltip */
      right: pixelToRem(16);
      border-width: pixelToRem(5);
      border-style: solid;
      border-color: transparent transparent $black transparent;
    }
  }
  &:hover,
  &:active {
    .tooltiptext {
      visibility: visible;
      opacity: 1;
    }
  }
}

.fieldWithTooltipWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
}

input:focus + .tooltip {
  visibility: visible;
}
textarea:focus + .tooltip {
  visibility: visible;
}

.tooltip:hover,
:active,
:visited {
  visibility: visible;
}

.currency {
  font-size: 0.875rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translatey(-50%);
}

.error {
  color: $rac-red;
}
