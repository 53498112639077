@import "../../../styles/colors";
@import "../../../styles/utils";
@import "../../../styles/breakpoints";

.alertInner {
  padding: pixelToRem(22) pixelToRem(14) pixelToRem(14);
  border: 1px solid $rac-dialog-border;
  color: $black;
}

.alertTitle {
  margin-bottom: pixelToRem(15);
  h3 {
    margin: 0 0 pixelToRem(25);
    font-size: pixelToRem(18);
    line-height: pixelToRem(29);
    font-weight: 700;
    text-align: center;
  }
}

.alertContent {
  margin-bottom: pixelToRem(24);
  p {
    font-size: pixelToRem(14);
    line-height: pixelToRem(23);
    margin: 0 0 pixelToRem(16);
  }
}

.withBorder {
  border-bottom: 1px solid $rac-light-grey;
}

.btnInfo,
.btnBorder {
  text-transform: uppercase;
  padding: pixelToRem(9) pixelToRem(10) pixelToRem(10);
}

.btnInfo {
  @include fill-button($white, $rac-blue);
  flex: 1 0 auto;
  max-width: pixelToRem(137);
}

.btnBorder {
  @include border-button();
  flex: 1 0 auto;
  max-width: pixelToRem(88);
  margin-right: pixelToRem(16);
}

.alertActions,
.alertActionsSingle {
  display: flex;
}

.alertActions {
  justify-content: space-between;
}

.alertActionsSingle {
  justify-content: center;
}

@include tabletUp {
  .root {
    width: 534px;
  }
}
