@import "../../../../../../styles/utils";

.loadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    margin-right: pixelToRem(10);
  }
}
