@import "../../../../styles/colors";
@import "../../../../styles/utils";

.searchBox {
  position: relative;
  width: pixelToRem(320);
  padding: pixelToRem(10) pixelToRem(15);
  border-right: pixelToRem(1) solid $PRIMARY_04;

  &.collapsed {
    cursor: pointer;
  }

  .arrow {
    position: absolute;
    top: pixelToRem(10);
    right: pixelToRem(10);
    color: $PRIMARY_04;
    cursor: pointer;
    z-index: 2600;
  }

  .tooltip {
    color: $BLACK_MEDIUM;
    opacity: 0.8;
    font-size: pixelToRem(14);
  }

  .input {
    width: 100%;
  }


  .searchButton {
    margin-top: pixelToRem(10);
  }
}
