@import "../../../../styles/colors";
@import "../../../../styles/breakpoints";
@import "../../../../styles/utils";

.purchaseOrderPage {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;

  @include desktopUp {
    margin: 0 auto;
    max-width: 600px;
  }

  input[type="text"] {
    @include mobileDown {
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  button { // inhibit bootstrap style from container
    &:focus {
      outline: none;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
  }

  .formPurchaseOrder {
    border-radius: 0.5rem;
    box-shadow: 0 2px 10px 0 rgba(68, 68, 68, 0.5);
    margin: 2rem 1rem 1.5rem 1rem;
  }

  .formPurchaseOrderFooter {
    .actionsContainer {
      background-color: $white;
      border-radius: 0 0 0.5rem 0.5rem;
      overflow: hidden;
      border-top: 2px solid $rac-action-border;
      padding: 1rem;

      > button {
        width: 100%;
      }
    }
  }

  input[name="weeklyRate"],
  input[name="costPerItem"],
  input[name="totalCost"] {
    padding: 0.5rem 1.625rem;
  }

  .description {
    height: 104px;
  }

  .leftActions {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    padding-bottom: 1rem;

    button {
      &:first-of-type {
        margin-right: pixelToRem(16);
      }
    }
  }

  .intermediateActions {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    button {
      margin-right: pixelToRem(16);
      flex-grow: 1;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .saveErrContainer {
    border: 1px solid $rac-red;
    padding: pixelToRem(10);
    margin-right: auto;
    margin-bottom: pixelToRem(16);
  }

  .headerContainer {
    display: flex;
    align-items: center;
    padding: pixelToRem(4) 0;
  }

  .backArrow {
    cursor: pointer;
    fill: $white;
    left: -8px;
    margin-right: pixelToRem(10);
    position: relative;
  }
}

.form {
  input:required,
  input:invalid {
    box-shadow: none;
  }

  label {
    color: $black;
    font-size: pixelToRem(14);
    font-weight: 600;

    // Inhibit bootstrap styles
    display: block;
    margin-bottom: 0;
    line-height: 1.6;
  }

  input[type="text"],
  input[type="number"] {
    outline: none;
    background-color: $white;
    border: pixelToRem(1) solid $rac-blue;
    color: $black;
    font-size: pixelToRem(14);
    line-height: pixelToRem(23);
    padding: pixelToRem(7) pixelToRem(32) pixelToRem(8) pixelToRem(8);
    width: 100%;
    border-radius: 0;
    -webkit-appearance: none;
    height: pixelToRem(40);

    &::placeholder {
      color: $black;
      font-style: italic;
      font-size: pixelToRem(14);
      font-weight: 400;
      line-height: pixelToRem(23);
      text-align: left;
    }

    &:focus {
      box-shadow: 0 0 pixelToRem(1) pixelToRem(1) $rac-grey;
    }

    &.invalid {
      border: pixelToRem(1) solid $rac-red;
    }

    &:disabled {
      background-color: $rac-grey-disabled;
      border-color: $rac-dark-grey;
      opacity: 1;
    }
  }

  .iconField {
    position: relative;
    > span {
      font-size: 0.875rem;
      font-weight: 600;
      position: absolute;
      top: 50%;
      left: 0.5rem;
      transform: translatey(-50%);
      line-height: pixelToRem(40);
    }
    input {
      padding: 0.5rem 1.625rem 0.5rem 1.5rem;
    }
  }

  .noFillField {
    input,
    .selectItem {
      background-color: $rac-grey-disabled;
      border-color: $rac-dark-grey;
    }
  }
}

.formBlock {
  align-items: flex-start;
  flex-direction: column;
  flex: 0 0 48%;
  margin-bottom: 0.625rem;
}

.cardTitleRounded {
  @include cardTitle($radius: 8px, $fontSize: 1.125rem);
}

.card {
  margin: 0;
}

.cardTitleSquared {
  @include cardTitle($fontSize: 1rem);
  padding: 0 pixelToRem(16);
  line-height: inherit;
}

.cardContent {
  padding: 1.125rem 0.875rem 1.125rem;
}

.error {
  color: $rac-red;
}

@include tabletUp {
  .cardTitleRounded {
    line-height: pixelToRem(24);
    border-top-left-radius: pixelToRem(4);
    border-top-right-radius: pixelToRem(4);
    padding: pixelToRem(16) pixelToRem(17);
  }

  .purchaseOrderPage {
    max-width: none;
    padding: 0;

    .formPurchaseOrder {
      margin: 0;
      padding: pixelToRem(24) pixelToRem(24) pixelToRem(140);
      border-radius: 0;
      box-shadow: none;
      min-height: calc(100vh - 101px);
      position: relative;
    }

    .formPurchaseOrderContent {
      border-radius: pixelToRem(4) pixelToRem(4) pixelToRem(8) pixelToRem(8);
      box-shadow: 0 2px 10px 0 rgba(68, 68, 68, 0.5);
    }

    .formCardsWrapper {
      padding: pixelToRem(16);
    }

    .formPurchaseOrderFooter {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;

      .actionsContainer {
        border-radius: 0;
        border-top: 1px solid $rac-dark-grey;
        background-color: $rac-card-border;
        display: flex;
        padding: pixelToRem(14);

        > button {
          width: auto;
        }

        [collapsed-sidebar] &.actionsContainer {
          margin-left: pixelToRem(-20);
        }
      }
    }

    .saveErrContainer {
      margin-right: pixelToRem(16);
      margin-bottom: 0;
    }

    .leftActions {
      padding-bottom: 0;
    }

    .intermediateActions {
      margin-right: pixelToRem(16);
      padding-bottom: 0;

      button {
        flex-grow: 0;
      }
    }
  }

  .card {
    margin-bottom: pixelToRem(16);
  }

  .form {
    .card {
      margin-bottom: pixelToRem(20);
    }
  }

  .cardContent {
    padding: pixelToRem(16) 0 0;
  }
}
