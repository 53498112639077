@import "../../../styles/colors";

.boxContainer {
  text-align: left;
  padding: 1rem 1rem;

  > p {
    padding: 0.5rem 0rem;
  }
}

.headerPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
