@import '../../../styles/breakpoints';
@import '../../../styles/utils';

.permissionsError {
  margin-top: pixelToRem(24);
  padding: 0 pixelToRem(16);
  color: #bb6a03; // $STATUS_YELLOW_06
  svg {
    margin-right: pixelToRem(8);
  }
}

@include tabletUp {
  .permissionsError {
    padding: 0 pixelToRem(24);
  }
}
