@import "../../../styles/colors";
@import "../../../styles/utils";
@import "../../../styles/breakpoints";

.wrapper {
  p,
  .list {
    font-size: pixelToRem(14);
    letter-spacing: pixelToRem(0.25);
    line-height: pixelToRem(23);
  }

  .list {
    letter-spacing: pixelToRem(0.1);
    padding-left: pixelToRem(20);
    ul,
    ol {
      padding-left: pixelToRem(20);
      margin-bottom: 0;
      list-style-type: disc;
    }
  }
}

.drawerContent {
  color: $black;
  padding: pixelToRem(18) pixelToRem(24);
  font-weight: 400;

  p,
  ul,
  ol {
    margin-top: 0;
    margin-bottom: pixelToRem(30);
  }
}

.listTitle {
  display: block;
  font-size: pixelToRem(14);
  letter-spacing: pixelToRem(0.25);
  line-height: pixelToRem(23);
  font-weight: 400;
  text-decoration: underline;
  padding-left: pixelToRem(10);
}
