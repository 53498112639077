@import "../../../../../styles/colors";
@import "../../../../../styles/breakpoints";
@import "../../../../../styles/utils";

.description {
  outline: none;
  background-color: $white;
  border: pixelToRem(1) solid $rac-blue;
  color: $black;
  font-size: pixelToRem(14);
  line-height: pixelToRem(23);
  padding: pixelToRem(7) pixelToRem(32) pixelToRem(8) pixelToRem(8);
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;
  height: pixelToRem(104);
  resize: none;

  &::placeholder {
    color: $black;
    font-style: italic;
    font-size: pixelToRem(14);
    font-weight: 400;
    line-height: pixelToRem(23);
    text-align: left;
  }

  &:focus {
    box-shadow: 0 0 pixelToRem(1) pixelToRem(1) $rac-grey;
  }

  &.invalid {
    border: pixelToRem(1) solid $rac-red;
  }

  &:disabled {
    background-color: $rac-grey-disabled;
    border-color: $rac-dark-grey;
    opacity: 1;
  }
}
