@import "../../../styles/colors";
@import "../../../styles/utils";

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .paper {
    background-color: $white;
    padding: pixelToRem(20);

    &:focus {
      outline: none;
    }
  }

  .paperContent {
    padding: 1rem;

    &.primary {
      border: pixelToRem(1) solid $PRIMARY_01;
    }

    &.danger {
      border: pixelToRem(1) solid $rac-red;
    }
  }

  .BackdropPropsRoot {
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(2px);
  }
}
