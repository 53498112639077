@import "../../../../../styles/colors";
@import "../../../../../styles/utils";

.searchTag {
  display: inline-block;
  cursor:  default;
  background-color: $rac-light-grey;
  color: $rac-dark-grey;
  font-size: pixelToRem(12);
  line-height: pixelToRem(12);
  border-radius: pixelToRem(8);
  padding: pixelToRem(3) pixelToRem(10) pixelToRem(2);
  font-weight: 600;
  margin-right: pixelToRem(5);
  margin-bottom: pixelToRem(5);

  &.removable {
    padding-right: 0;
  }

  .closeBtn {
    float: right;
    line-height: pixelToRem(12);
    padding: 0 pixelToRem(5);
    cursor: pointer;
  }
}
