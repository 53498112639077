@import "../../../../../styles/colors";
@import "../../../../../styles/utils";

.termUpdatedBox {
  width: pixelToRem(522);
  text-align: center;

  h2 {
    color: $BLACK_MEDIUM;
    font-size: pixelToRem(20);
    border-bottom: pixelToRem(1) solid #E9E9E9;
    margin: pixelToRem(10) pixelToRem(50) pixelToRem(20);
    padding-bottom: pixelToRem(20);
  }
}
