@import "../../../../../styles/colors";
@import "../../../../../styles/utils";
@import "../../../../../styles/breakpoints";

.cardOrderEmptyWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:focus {
    outline: none;
  }
}

.cardOrderEmpty {
  background-color: $white;
  border: 1px dashed $rac-blue;
  border-radius: 0.25rem;

  .cardOrderContent {
    padding: pixelToRem(16);
    min-height: pixelToRem(153);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  hr {
    border: none;
    border-bottom: 1px dashed $rac-blue;
    margin: 2.5rem 0 0 0;
  }

  h3 {
    margin: 0;
    font-size: pixelToRem(18);
    line-height: pixelToRem(29);
    font-weight: 700;
    max-width: pixelToRem(200);
  }
}

.initialMessage {
  font-weight: 600;
  text-align: center;
  margin: auto;
  max-width: pixelToRem(260);
  line-height: pixelToRem(24);
}

@include tabletUp {
  .cardOrderEmptyWrapper {
    height: auto;
    justify-content: normal;
  }

  .cardOrderEmpty {
    margin-bottom: pixelToRem(25);
    h3 {
      max-width: none;
    }
  }

  .initialMessage {
    margin: 0 auto;
    max-width: pixelToRem(330);
  }
}
