@import "../../../styles/colors";
@import "../../../styles/utils";
@import "../../../styles/breakpoints";

.searchPO {
  margin: pixelToRem(10) pixelToRem(20);
  margin-top: pixelToRem(30);

  label {
    color: $black;
    font-size: pixelToRem(14);
    font-weight: 600;

    display: block;
    margin-bottom: 0;
    line-height: 1.6;

    .requiredIndicator {
      color: $rac-red;
    }
  }

  .searchWrap {
    display: flex;
    width: pixelToRem(680);
    border: pixelToRem(1) solid $rac-blue;
    height: pixelToRem(400);

    &.collapsed {
      height: pixelToRem(42);
    }
  }

  @include desktopUp {
    .searchWrap {
      width: pixelToRem(1000);
    }
  }
}
