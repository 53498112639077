@import "../../../../../styles/colors";
@import "../../../../../styles/utils";

.selectItemComponent {
  outline: none;
  color: $black;
  font-size: 0.875rem;
  line-height: pixelToRem(23);
  width: 100%;
  border-radius: 0;
  -webkit-appearance: none;
  height: pixelToRem(40);
}
